import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  mixins: [sharedActions, roleActions],
  data() {
    return {
      sidebarItems: [
        {
          icon: "$dashboard",
          title: this.$trans("dashboard"),
          to: { name: "dashboard" },
          permissions: ["show_stats"],
        },
        {
          icon: "$calendar-month",
          title: this.$trans("calendar"),
          to: { name: "calendar" },
          permissions: [
            "show_bookings",
            "show_own_bookings",
            "show_unavailable_booking_slots",
            "show_own_unavailable_booking_slots",
          ],
        },
        {
          icon: "$cart",
          title: this.$trans("sales"),
          to: {
            name: "sales",
            params: { tab: "events" },
          },
          tabsId: "sales",
          permissions: [
            "show_bookings",
            "show_own_bookings",
            "show_user_subscriptions",
            "show_store_product_transactions",
            "create_store_product_transactions",
            "delete_store_product_transactions",
          ],
        },
        {
          icon: "$store",
          title: this.$trans("offer"),
          to: {
            name: "offer",
            params: { tab: "services" },
          },
          tabsId: "offer",
          permissions: [
            "add_services",
            "add_categories",
            "edit_categories",
            "edit_services",
            "show_subscriptions",
            "edit_subscriptions",
            "edit_store_products",
            "delete_store_products",
            "add_store_products",
          ],
        },
        {
          icon: "$account-multiple",
          title: this.$trans("users"),
          to: { name: "users" },
          permissions: ["edit_users"],
        },
        {
          icon: "$clipboard-account",
          title: this.$trans("employees"),
          to: { name: "employees" },
          permissions: ["add_employees", "edit_employees"],
        },
        {
          icon: "$availability",
          title: this.$trans("availability"),
          to: { name: "availability" },
          permissions: [
            "add_availability_schedules",
            "show_availability_schedules",
            "edit_availability_schedules",
            "delete_availability_schedules",
            "add_own_unavailable_booking_slots",
            "show_own_unavailable_booking_slots",
            "edit_own_unavailable_booking_slots",
            "delete_own_unavailable_booking_slots",
          ],
        },
        {
          icon: "$plugins",
          title: this.$trans("tools"),
          to: {
            name: "tools",
            params: { tab: "websites" },
          },
          tabsId: "tools",
          permissions: [
            "show_user_images",
            "mobile_app_builder_editor",
            "website_builder_editor",
          ],
        },
        {
          icon: "$connection",
          title: this.$trans("integrations"),
          to: { name: "integrations" },
        },
        {
          icon: "$send",
          title: this.$trans("notifications"),
          to: {
            name: "notifications",
            params: { tab: "settings" },
          },
          tabsId: "notifications",
          permissions: [
            "edit_packages",
            "show_newsletter",
            "show_notifications",
          ],
        },
        {
          icon: "$cog",
          title: this.$trans("settings"),
          to: {
            name: "settings",
            params: {
              tab:
                this.loggedUser &&
                (!this.loggedUser.employee.id ||
                  !this.loggedUserCanEditEmployees)
                  ? "profile"
                  : "company",
            },
          },
          tabsId: "settings",
          permissions: ["edit_packages", "payments"],
        },
        {
          icon: "$card",
          title: this.$trans("billing"),
          to: {
            name: "billing",
            params: { tab: "data" },
          },
          tabsId: "billing",
          permissions: ["payments"],
        },
        {
          icon: "$logout",
          title: this.$trans("logout"),
          action: () => {
            this.$store.commit("setup/SET_PRELOADER", true, { root: true });
            this.$store.dispatch("auth/logoutUserAndDestroySession");
          },
        },
      ],
    };
  },
};
